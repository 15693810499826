import React, { useState, useEffect, useRef } from "react"
import { Button, Offcanvas, Form, Modal } from "react-bootstrap"
import { navigate } from "gatsby"
import {
  createStaffToPay,
  sendPaySuccessEmail,
  getWindowsLocation,
  getDeviceCode,
} from "../../api/app.ts"
import { EventUtil } from "../../utils/tools.ts"

const PopUpDialog = props => {
  const priceIds = {
    annually: "price_1MFZ6YEMcpObtdMwoQjuM3aO",
    monthly: "price_1MFZ6YEMcpObtdMwUygYGKIG",
  }

  const [isShowPop, setIsShowPop] = useState(false)
  const newDataStore = useRef({ email: "", emailSub: false, path: "" })

  const handlePopClose = () => {
    setIsShowPop(false)
  }
  const handlePopOpen = () => {
    setIsShowPop(true)
  }

  const handleResend = async (needClosePop = true) => {
    if (needClosePop) {
      handlePopClose()
    }
    if (newDataStore.current.email === "") {
      navigate("/landingfour")
      return
    }

    const params = {
      ...newDataStore.current,
      priceId: priceIds.annually,
      origin: getDeviceCode(),
      autoEmail:true
    }
    const { success, data } = await createStaffToPay(params)
    if (success) {
      // sendPaySuccessEmail({ userId: data.userId })
      handlePopOpen()
    }
  }

  useEffect(() => {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      const sessionData=JSON.parse(sessionStorage.getItem('TEMP_LANDING')) ?? {}
        if (Object.keys(sessionData).length !== 0) {
          newDataStore.current = { ...sessionData }
        }
    }

    if (newDataStore.current.email !== "") {
      setIsShowPop(true)
      // handleResend(false)
    }
  }, [])
  return (
    <Modal
      show={isShowPop}
      onHide={handlePopClose}
      centered
      className="HomePopContent"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <img src="/assets/icons/logo.png" className="poplogo" />
      </Modal.Header>
      <Modal.Body>
        <p className="top">
          <img
            src="/assets/icons/checkbox.png"
            alt="icon"
            width={16}
            height={16}
            style={{ marginRight: "4px" }}
          />
          Free trial invitation has been sent
        </p>
        <p className="bottom">
          Check your email and activate your Cubo online office now.
        </p>
      </Modal.Body>
      <Modal.Footer className="popFooter">
        <div className="close" onClick={handlePopClose}>
          Got it
        </div>
        <div className="resend" onClick={handleResend}>
          Resend
        </div>
      </Modal.Footer>
    </Modal>
  )
}
export default PopUpDialog
