import React, { useEffect, useState, useRef } from "react"
import { Link } from "gatsby"
import { Button } from "react-bootstrap"
const COOKIESKEY = "OFFICAL_WEB_COOKIES"

// const createDB=()=>{
//   if( typeof window !== "undefined"){
//     const request = window.indexedDB.open(COOKIESKEY);

//   }
//   return false
// }

const ListContent = [
  {
    label:
      "Used solely to carry out or facilitate the transmission of communications over a network; or strictly necessary to provide an online service (e.g. our website or a service on our website) which you have requested.",
  },
  {
    label:
      "We'd like to set Google Analytics cookies to help us improve our website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify anyone.",
  },
  {
    label:
      "These cookies are used to make advertising messages more relevant to you and your interests. They also perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed, and in some cases selecting advertisements that are based on your interests.",
  },
]

const CookiesSetting = () => {
  const isBrowser = typeof window !== "undefined"
  const preCookiesInfo = isBrowser ? localStorage.getItem(COOKIESKEY) ?? {} : {}

  const [isMobile, setIsMobile] = useState(false)
  const checkScreenSize = () => {
    if (isBrowser && window.innerWidth < 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  const [showBar, setShowBar] = useState(
    Object.keys(preCookiesInfo).length === 0
  )
  const [containIndex, setContainIndex] = useState(0)
  const [selectLevel, setSelectLevel] = useState(0)

  useEffect(() => {
    checkScreenSize()
    window.addEventListener("resize", checkScreenSize, { passive: true })
  }, [])

  const confirmEssentialCookies = () => {
    const payload = { accept: false, level: 0 }
    if (isBrowser) {
      localStorage.setItem(COOKIESKEY, JSON.stringify(payload))
    }
    setShowBar(false)
  }

  const acceptAllCookies = () => {
    const payload = { accept: true, level: 4 }
    if (isBrowser) {
      localStorage.setItem(COOKIESKEY, JSON.stringify(payload))
    }
    setShowBar(false)
  }
  const handleCustomCookies = () => {
    setContainIndex(1)
  }
  const handleCancle = () => {
    setContainIndex(0)
  }

  const handleConfirm = () => {
    const payload = { accept: true, level: selectLevel }
    if (isBrowser) {
      localStorage.setItem(COOKIESKEY, JSON.stringify(payload))
    }
    setShowBar(false)
  }

  return isBrowser ? (
    <div
      id="cookiesBar"
      style={{ display: showBar && !isMobile ? "block" : "none" }}
    >
      <div className="top">
        <img
          src="/assets/icons/cookie_ring.png"
          alt="cubo_logo"
          style={{ width: 24, height: 24, marginRight: "4px" }}
        />
        <span style={{ display: containIndex === 0 ? "inline-block" : "none" }}>
          COOKIES HELP US IMPROVE YOUR EXPERIENCE
        </span>
        <span style={{ display: containIndex === 1 ? "inline-block" : "none" }}>
          CUSTOMISE COOKIES
        </span>
      </div>
      <div className="container">
        <div
          className="info"
          style={{ display: containIndex === 0 ? "block" : "none" }}
        >
          <p >
            We use essential cookies to ensure that our website works as
            expected as well as optional cookies to improve our website, provide
            functionality to give you a better browsing experienc,enable social
            media features and ensure that you see content that is relevant to
            you.
          </p>
          <p >
            The essential cookies cannot be disabled unless you change your
            browser settings, but you can manage your preferences for the
            optional ones by clicking ”Customize Cookies”. For more information
            about the cookies we use a and what they do, please read our (
            <Link to="/privacypolicy">&nbsp;Privacy Policy&nbsp;</Link>)
          </p>
        </div>

        <div
          className="list"
          style={{
            display: containIndex === 1 ? "flex" : "none",
            flexDirection: "column",
          }}
        >
          {ListContent.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  position: "relative",
                  margin: "0 0 8px",
                  height: 48,
                }}
              >
                <input
                  name="level"
                  type="radio"
                  value={index + 1}
                  onClick={e => {
                    setSelectLevel(index + 1)
                  }}
                ></input>
                <span
                  style={{
                    color: selectLevel - 1 === index ? "#5856D6" : " #999999",
                  }}
                >
                  {item.label}
                </span>
              </div>
            )
          })}
        </div>

        <div style={{ marginTop: "12px" }}>
          <div style={{ display: containIndex === 0 ? "block" : "none" }}>
            <div>
              <Button
                className="rounded-btn  outlined-btn  second  textWhite"
                style={{
                  backgroundImage: " linear-gradient(90deg, #8d49f7, #6b53ff) ",
                  width: "240px",
                  height: 40,
                  fontSize: "14px",
                  fontWeight: "400",
                  padding: 0,
                  marginRight: "20px",
                }}
                onClick={acceptAllCookies}
              >
                ACCEPT ALL OPTIONAL COOKIES
              </Button>
              <Button
                className="rounded-btn outlined-btn  first"
                style={{
                  backgroundColor: "white",
                  width: "240px",
                  height: 40,
                  fontSize: "14px",
                  fontWeight: "400",
                  padding: 0,
                }}
                onClick={confirmEssentialCookies}
              >
                USE ESSENTIAL COOKIES ONLY
              </Button>
            </div>
            <div className="customise" onClick={handleCustomCookies}>
              <div>
                <span> CUSTOMISE COOKIES</span>
                &nbsp;
                <img
                  src="/assets/svgs/cookie_arrow.svg"
                  alt="arrow"
                />
              </div>
            </div>
          </div>

          <div style={{ paddingTop: "16px" }}>
            <div style={{ display: containIndex === 1 ? "flex" : "none" }}>
              <Button
                className="rounded-btn  outlined-btn  second  textWhite"
                style={{
                  backgroundImage: " linear-gradient(90deg, #8d49f7, #6b53ff) ",
                  width: "240px",
                  height: 40,
                  fontSize: "14px",
                  fontWeight: "400",
                  padding: 0,
                  marginRight: "20px",
                }}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
              <Button
                className="rounded-btn outlined-btn first"
                style={{
                  backgroundColor: "white",
                  width: "240px",
                  height: 40,
                  fontSize: "14px",
                  fontWeight: "400",
                  padding: 0,
                }}
                onClick={handleCancle}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default CookiesSetting
