import { Link } from "gatsby"
import React, { useState, useEffect, useContext } from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"


const GDPR = () => {
  const [isMobile, setIsMobile] = useState(false)
  const checkScreenSize = () => {
    if (window.innerWidth <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    checkScreenSize()
    window.addEventListener("resize", checkScreenSize,   {passive:true})
  }, [])

  return (
    <>
    <Helmet htmlAttributes={{ lang: "en" }} title={'General Data Protection Regulation (GDPR) - Cubo' }>
      <meta name="description" content={'Cubo is compliant with EU data protection requirements, including those set out in the General Data Protection Regulation (GDPR).'} />
    </Helmet>
    <Layout>
      <div
        className="gdpr-section"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div style={{ maxWidth: "1024px", padding: "0 20px" }}>
          <h1
            style={{
              textAlign: "center",
              marginBottom: "24px",
              fontWeight: "600",
              fontSize: "24px",
            }}
          >
            EU Data Protection and GDPR Compliance
          </h1>
          <p>
            Cubo is fully compliant with the EU General Data Protection
            Regulation(GDPR).
          </p>
          <p>
            GDPR adds new requirements regarding how companies should protect
            the data that they process. While we've always been compliant from a
            <Link to="/technicalperspective"  >
              &nbsp;technical perspective&nbsp;
            </Link>
            , we've also updated our
            <span className="blueText">
              <Link to="/privacypolicy">&nbsp;Privacy Policy&nbsp;</Link>
            </span>
            to reflect our compliance with these requirements. In addition,
            below you'll find information about our sub-processors, our
            <Link to="/dpa"  >&nbsp;Data Processing Addendum (DPA)&nbsp;</Link>.
          </p>
          <h3>Data Processing Addendum</h3>
          <p>
            The Data Processing Addendum (DPA) to our Terms of Service details
            the rights and responsibilities for the processing and security of
            customer data.
          </p>
          <h3>Sub-processors</h3>
          <p>
            Cubo uses third party sub-processors, such as cloud computing
            providers and customer support software, to provide our application
            to our customers. We enter into GDPR-compliant data processing
            agreements with each sub-processor, extending GDPR safeguards
            everywhere personal data is processed.
          </p>
          <p>
            Here's our list of sub-processors. All of the data is processed in
            the United States.
          </p>
          <h5 style={{ fontSize: isMobile ? "14px" : "" }}>
            AWS - Database storage service
          </h5>
          <h5 style={{ fontSize: isMobile ? "14px" : "" }}>
            Twilio - text message delivery
          </h5>
          <h5 style={{ fontSize: isMobile ? "14px" : "" }}>
            Xunfei-Voice-to-text Service
          </h5>
          <h5 style={{ fontSize: isMobile ? "14px" : "" }}>
            Qiniu-Multimedia data processing service
          </h5>
          <h5 style={{ fontSize: isMobile ? "14px" : "" }}>
            Agora-Instant messaging service
          </h5>
          <h5
            style={{ fontSize: isMobile ? "14px" : "", textAlign: "initial" }}
          >
            Auth0-Authentication and authorization platform
          </h5>
          <h5
            style={{ fontSize: isMobile ? "14px" : "", textAlign: "initial" }}
          >
            Clearout-Email authentication and authorization platform
          </h5>
          <h5 style={{ fontSize: isMobile ? "14px" : "" }}>
            Nylas-Calendar Editing Service
          </h5>
          <h5 style={{ fontSize: isMobile ? "14px" : "" }}>
            Stripe-Internet payment function services
          </h5>
          <h5 style={{ fontSize: isMobile ? "14px" : "" }}>
            Mailgun-Mail Processing Service
          </h5>
          <h5 style={{ fontSize: isMobile ? "14px" : "" }}>
            Mailreach-Mail Processing Service
          </h5>
          <h5 style={{ fontSize: isMobile ? "14px" : "" }}>
            Mailchimp-Mail Processing Service
          </h5>
          <br />
          More Information
          <br />
          Contact us anytime for additional details.
        </div>
      </div>
    </Layout>
    </>
  )
}

export default GDPR
